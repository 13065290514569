.container {
	width: 100%;
	min-width: 480px;
	max-width: 480px;
	max-height: 60vh;
	overflow-y: scroll;
	position: relative;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	.details_container {
		border-radius: 6px;
		border: 1px solid var(--ltGreyscale300);
		background: var(--ltWhite);
		box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

		.icon_container_wrap {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-bottom: 24px;
			gap: 0px;

			.order_success_graphic {
				width: 250px;
				height: 250px;
				margin: 0 auto;
				margin-top: -60px;
				margin-bottom: -55px;
				svg {
					width: 100%;
					height: 100%;
					display: block;
				}
			}

			.content_container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-bottom: 20px;
			}

			.booking_track_icons {
				width: 140px;
				.track_icon {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.track_icon_text {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 30px;
					margin-top: 4px;
					margin-left: -30px;
				}
			}
		}
	}

	.lab_payment_container {
		border: 1px solid var(--ltGreyscale300);
		background: var(--ltWhite);
		box-shadow: 0px 2px 15px 0px rgba(17, 41, 80, 0.1);
		border-radius: 6px;
	}
}

.button_container {
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	padding: 16px 24px 16px 24px;
	position: sticky;
	bottom: 0;
	border-radius: 0px 0px 12px 12px;
	border-top: 1px solid var(--ltGreyscale300);
}
