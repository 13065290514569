.paymentMethodInfo {
	border: 1px solid var(--ltGreyscale300);
	border-radius: 6px;
	background: var(--ltWhite);
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

	&_header {
		padding: 10px 20px;
		display: flex;
		align-items: center;
		gap: 1.25rem;
		border-bottom: 1px solid var(--ltGreyscale300);
	}

	&_body {
		padding: 20px 20px 20px;
		.onlinePayment {
			display: flex;
			align-items: center;
			gap: 8px;
			border-radius: 10px;
			margin-bottom: 12px;

			& > snap {
				line-height: 150%; /* 24px */
			}
		}

		.paymentMethods {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 12px;
			
			.image{
				width: 100%;
				height: 100%;
				object-fit: contain;
				border-radius: 6px;
			}
		}

		& > p {
			line-height: 24px;
		}
	}
}
